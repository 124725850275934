.Reason{
    display: flex;
    justify-content: flex-start;
    padding-top: 2rem;
}


.left-r{
    display: grid;
    grid-template-columns: repeat(3,230px);
    gap: 1rem;
    grid-template-rows: repeat(2,250px);
}

.left-r>img{
    object-fit: cover;
}

.left-r>:nth-child(1){
    width:7rem;
    display: grid;
    margin-left: 0.5rem;
    grid-row: 1/3;
    justify-content:start;
    height: 15.6rem;

}
.left-r>:nth-child(2){
    width:15rem;
    height: 8rem;
    grid-column: 2;
    grid-row: 1;

}

.left-r>:nth-child(3){
    width:7rem;
    height: 7rem;
    grid-column: 2;
    grid-row: 2;
    
}
.left-r>:nth-child(4){
    grid-row: 2;
    height:220px;
    width: 220px;
    grid-column: 2.5;

}

    .why{
        padding-left: 2rem;
        font-size: 20px;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        text-transform: uppercase;
    }

    .why>:nth-child(1){
        color: var(--orange);
        font-weight: bold;
    }
    .header-r{
        display: flex;
        font-size: 45px;
        color: #ffff;
        font-weight: bolder;
        gap: 0.8rem;
        flex-direction: row;
    }
    .why>span>img{
        width: 30px;
        height: 30px;
        margin: 0 20px;
    }

    .why>span{
        font-size: 16px;
        display: inline;
        color: white;
        font-weight:bold
    }
    .ad>span{
        display: block;
        padding: 5px;
        color: grey;
        font-weight: lighter;
    }
    .ad>img{
        width: 40px;
        height: 38px;
        margin: 10px;
        
    }
    @media screen and (max-width:768px) {
    .left-r{
        position: relative;
    }
    
    .left-r>:nth-child(1){
        width:3.8rem;
        display: grid;
        margin-left: 0.5rem;
        grid-row: 1/3;
        justify-content:start;
        height: 10.6rem;
    
    }
    .left-r>:nth-child(2){
        width:4.8rem;
        height: 6rem;
        grid-column: 2;
        grid-row: 1;
        margin-left: -3.5rem;
    
    }
    
    .left-r>:nth-child(3){
        width:4.9rem;
        height: 4rem;
        grid-column: 2;
        grid-row: 2;
        margin-top: -2.3rem;
        margin-left:-3.5rem ;
        
    }
    .left-r>:nth-child(4){
        grid-row: 2;
        height:220px;
        width: 220px;
        grid-column: 2.5;
        margin-left: -3.5rem;
    
    }
    .why{
        font-size: 32px;
        display: block;
        text-transform: uppercase;
        
    }
    .header-r{
        position: absolute;
        margin-top: 11.5rem;
        left: 0.7rem;
        display: flex;
        font-size: 1.1rem;
        color: #ffff;
        font-weight: bolder;
        flex-direction: column;
    }

   
    .Reason{
        margin-bottom: 13rem;
    }
   #hi{
    position: absolute;
    left: 1rem;
    margin-top: 17rem;
    margin-bottom: 3rem;
   }
   #ello{
    position: absolute;
    left: 1rem;
    margin-top: 19rem;
    margin-bottom: 3rem;
   }
   #hello{
    position: absolute;
    left: 1rem;
    margin-top: 21rem;
    margin-bottom: 3rem;
   }
   #cello{
    position: absolute;
    left: 1rem;
    margin-top: 23rem;
    margin-bottom: 3rem;
   }
   .ad>span{
    position: absolute;
    left: 1.4rem;
    margin-top: 23.3rem;
    font-size: 16px;
    display: block;
    padding: 5px;
    color: grey;
    font-weight: lighter;
}
.ad>img{
    position: absolute;
    left: 1rem;
    margin-top:24.7rem;
    width: 35px;
    height: 28px;

    
}
.ad>img:nth-child(2){
    position: absolute;
    left: 2.4rem;
    margin-top:24.7rem;
    width: 35px;
    height: 28px;

    
}
.ad>img:nth-child(3){
    position: absolute;
    left: 4rem;
    margin-top:24.7rem;
    width: 35px;
    height: 28px;

    
}
.ad>img:nth-child(4){
    position: absolute;
    left: 1rem;
    margin-top:24.7rem;
    width: 35px;
    height: 28px;

    
}


    }