.header{
    display:flex;
    justify-content: space-between;

}

#bar{
    display: inline-block;
    width: 30px;
    height: 20px;
}

.logo{
width:7rem;
height:2rem;
}

.nav{
    justify-content: flex-end;
    position:relative;
    bottom:2rem;
    right:4rem;
    list-style:none;
    display:flex;
    gap:1rem;
    color:white;
    font-size: 0.5rem;
    cursor: pointer;
}

.nav>li:hover{
    color: orange;
}
@media screen and (max-width:768px){
    
    .logo{
        transform: scale(0.8);
        margin-left: -1.7rem;
        position: relative;
        margin-bottom: 20px;
    }
    .nav{
        position: fixed;
       left: 0;
       margin-bottom: 4rem;
        z-index: 6;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 3rem;
        background-color: var(--appColor);
        padding: 1em;
        padding-left: 15px;
        padding-right: -4;
        right: -4.2rem;
        transition:height 1s ease-in;
    }
    
    .bye{
        position: relative;
        top:-1rem;
        left:8.5rem;
    }
    
}