
.header{
    margin-top: 4rem;
    display: flex;
    justify-content: space-around;
}
.header>span{
    font-size: 45px;
    text-transform: uppercase;
    font-style: italic;

}
.header>:nth-child(2){
    color: white;
}

.programcategories{
    display: flex;
    margin-top: 1.6rem;
    gap: 0.5rem;
}
.category{
    height: 5.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
    margin: 0rem 14px 2rem 14px;
    padding: 1.6rem 1rem;
    background-color: grey;
    color: white;
}


.joinnow{
     padding-top: 10px
}

.category>:nth-child(1){
    
    fill: white;
    padding-bottom: 30px;
}
.category>:nth-child(2){
   font-size: 20px;
   padding-bottom: 8px;
   font-weight: bold;
color: white;
}
.category>:nth-child(3){
   font-size: 15px;
   padding-bottom: 8px;
   color: white;
}

.category:hover{
    background: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    cursor:pointer;
}
@media  screen and (max-width:768px) {
    .program{
        flex-direction: column;
        display: flex;
    }
    .header{
        margin-top: 13rem;
        display: block;
        font-size:10px;
        justify-content: space-around;
    }
    
    .header>span{
        padding: 0.3rem 5px;
        display: block;
        text-align: center;
        font-size: 23px;
        text-transform: uppercase;
        font-style: italic;
    
    }
    .programcategories{
        margin-bottom: 42rem;
        height: 0rem;
        width: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 16px;
        background-color: grey;
        color: white;
    }
    .category{
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


}