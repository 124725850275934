body{
    margin: 0;
    padding: 0;
}
.join{
    position: relative;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    margin-top: -5rem;
    margin-left: 2rem;
    color: white;
}
.content{
    font-weight: bolder;
    display: flex;
    padding: 0 1.3rem;
    justify-content: flex-start;
    flex-direction: column;
    text-transform: uppercase;
    flex:1 1;
    font-size: 1.3rem;
    gap: 0.6rem;
}
.content>div>span{
    line-height: 1rem;
    padding:15px;
}
.join>:nth-child(1){
    border: 2px solid orange;
    border-radius: 20%;
    width: 6rem;
    padding: 0;
    height: 0px;
    margin-left: 2rem;
    margin-bottom: 20px;
}
hr{
    color: #ffff;
    width: 100vw;
    height: 0px;
    font-weight: bolder;
    margin-top: 2.8rem;
}
footer{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
}
footer>img{
    width: 30px;
    height: 25x;

}
#logo{
    margin-top: 1.5rem;
    width: 5rem;
    height: 1.6rem;
}

#noot{
    text-align: center;
}
.btn-email{
    background-color: orange;
    color: white;
    width: 3.5rem;
    height: 28px;
    border: 0;
    font-weight: bolder;
    font-size:14px;
    height: 1.6rem;
}
form{
    position: absolute;
    right: 4rem;
    top:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width:12rem;
    height: 1.8rem;
    flex-direction: row;
    gap: 0.5rem;
    background-color: var(--gray);

    transform: scale(0.9);
    padding: 10px;
}
input{

    height: 1.5rem;
    color: white;
    font-size: 17px;
    outline: none;
    border: 0;
    background-color: transparent;
}
::placeholder{
    color: var(--lightgray);
}
.blur1{
    width: 13rem;
    height: 10rem;
    filter: blur(220px);
    background-color: red;
    left: 5rem;
    margin-bottom: 4rem;
}
.blur2{
    width: 15rem;
    height: 16rem;
    right: 5rem;
}
@media  screen and (max-width:768px) {
   
.content{
    font-weight: bolder;
    display: flex;
    padding: 0 1.3rem;
    justify-content: flex-start;
    flex-direction: column;
    text-transform: uppercase;
    flex:1 1;
    font-size: 0.9rem;
    white-space: nowrap;
    transform: scale(0.8);
    margin-left: -2rem;
    gap: 0.6rem;
    font-weight: bolder;
}
.content>div>span{
    line-height: 1rem;
    padding:15px;
}
.join>:nth-child(1){
    border: 2px solid orange;
    border-radius: 20%;
    width: 4rem;
    height: 0px;
    margin-left: 1rem;
    margin-bottom: 20px;
}



form{
  margin-top: 2rem;
  transform: scale(0.7);
  width: 100%;
  right: 0rem;
  

}
.join{
    position: relative;
    bottom: 0;
    height: 90vh;
    width: 100vw;
    margin-top: -5rem;
    margin-left: 2rem;
    color: white;
}

hr{
    margin-top: 6rem;
}
footer>img{
    margin-top: 1rem;
    transform: scale(0.8);
}

}